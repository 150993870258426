<template>
  <div class="d-flex align-center gap-2">
    <div
      v-for="(image, index) in displayedImages"
      :key="index"
      class="cursor-move relative"
    >
      <v-img
        :aspect-ratio="1"
        :src="image && image.slice(0, 5) !== 'blob:' ? image : undefined"
        class="grey lighten-3 rounded tp-boder-thin"
        height="60px"
        width="60px"
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey lighten-5"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <div v-if="index === 4 && remainingImages > 0" class="overlay">
        +{{ remainingImages }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    displayedImages() {
      return this.images.slice(0, 5);
    },
    remainingImages() {
      return this.images.length - 5;
    }
  }
};
</script>

<style scoped>
.gap-2 {
  gap: 8px;
}

.gallery-item {
  width: 60px;
  height: 60px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
</style>
