<template>
  <div class="tab-feedback-images">
    <v-data-table
      :headers="headers"
      :items="saleTabActive.details"
      :loading="
        orderStatusRequest.value === 'loading-getOrderPaymentTransactions'
      "
      class="table-scroll tp-table__row-pointer datatable px-3 py-2 rounded-0 h-full"
      disable-pagination
      disable-sort
      hide-default-footer
      no-data-text="Không có dữ liệu"
      v-bind="$attrs"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="my-6">{{ index + 1 }}</div>
            </td>
            <td>
              <div class="my-4">
                <div class="font-weight-bold">
                  {{ item.option.product_name }}
                  <v-hover
                    v-if="item.cur_promotions && item.cur_promotions.length > 0"
                    v-slot:default="{ hover }"
                  >
                    <v-icon
                      :class="{ 'red--text text--accent-2': hover }"
                      class="red--text text--accent-1 ml-1"
                      size="18px"
                      @click="
                        openModalSaleOrderPromotion(
                          item,
                          index,
                          item.cur_promotions
                        )
                      "
                    >
                      mdi-wallet-giftcard
                    </v-icon>
                  </v-hover>
                </div>
                <div
                  class="
                            text-subtitle-2
                            font-weight-bold
                            grey--text
                            text--darken-2
                          "
                >
                  <span>{{ item.option.SKU }}</span>
                  <span v-if="item.option.name"> - </span>
                  <span
                    class="font-weight-light tag-p__mb-0"
                    v-html="item.option.name"
                  >
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-center">
                <list-image :images="item.feedback_images"></list-image>

                <!-- <v-btn
                  class="mx-2"
                  elevation="0"
                  fab
                  small
                  color="grey lighten-3"
                  @click="handleEditFeedbackImages(item)"
                >
                  <v-icon color="black" size="24px"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn> -->
              </div>
            </td>
            <td>
              <div class="py-2">
                {{ item.content }}
              </div>
            </td>
            <td>
              <v-btn
                class="mx-2"
                elevation="0"
                fab
                small
                color="grey lighten-3"
                @click="handleEditFeedbackImages(item)"
              >
                <v-icon color="black" size="24px"
                  >mdi-square-edit-outline</v-icon
                >
              </v-btn>
            </td>
          </tr>

          <modal-edit-feedback :product="productActive"></modal-edit-feedback>
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">Không có dữ liệu</td>
          </tr>
        </tbody></template
      >
    </v-data-table>
  </div>
</template>

<script>
import ListImage from "./components/ListImage.vue";
import ModalEditFeedback from "./components/ModalEditFeedback.vue";

export default {
  name: "TabFeedbackImages",

  components: { ListImage, ModalEditFeedback },

  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id",
          width: "50px"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name",
          width: "400px"
        },
        {
          text: "Ảnh feedback",
          align: "start",
          sortable: false,
          value: "feedback_images"
        },
        {
          text: "Nội dung feedback",
          align: "start",
          sortable: false,
          value: "content"
        },
        {
          text: "Chỉnh sửa",
          align: "start",
          sortable: false,
          value: "edit",
          width: "50px"
        }
      ],
      productActive: null
    };
  },

  computed: {
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    async openModalSaleOrderPromotion(item, index, promotions) {
      // Set option and option index into state
      await this.$store.dispatch("SALE/setSaleTabActiveProductOption", item);
      await this.$store.dispatch(
        "SALE/setSaleTabActiveProductOptionIndex",
        index
      );

      this.$modal.show({
        name: "modal-sale-promotion",
        payload: {
          promotions: promotions
        }
      });
    },

    handleEditFeedbackImages(item) {
      this.productActive = item;
      this.$modal.show({
        name: "modal-edit-feedback"
      });
    }
  }
};
</script>

<style scoped>
.tab-feedback-images {
  background-color: #fff;
  min-height: calc(100vh - 96px);
}

.cursor-move {
  cursor: move;
}

.tab-feedback-images {
  .gallery-add-custom {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 60px !important;
    transition: all 0.4s ease;
    width: 60px !important;

    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }
}
</style>
