var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-feedback-images"},[_c('v-data-table',_vm._b({staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2 rounded-0 h-full",attrs:{"headers":_vm.headers,"items":_vm.saleTabActive.details,"loading":_vm.orderStatusRequest.value === 'loading-getOrderPaymentTransactions',"disable-pagination":"","disable-sort":"","hide-default-footer":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',[_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"my-6"},[_vm._v(_vm._s(index + 1))])]),_c('td',[_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.option.product_name)+" "),(item.cur_promotions && item.cur_promotions.length > 0)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"red--text text--accent-1 ml-1",class:{ 'red--text text--accent-2': hover },attrs:{"size":"18px"},on:{"click":function($event){return _vm.openModalSaleOrderPromotion(
                        item,
                        index,
                        item.cur_promotions
                      )}}},[_vm._v(" mdi-wallet-giftcard ")])]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(item.option.SKU))]),(item.option.name)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',{staticClass:"font-weight-light tag-p__mb-0",domProps:{"innerHTML":_vm._s(item.option.name)}})])])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('list-image',{attrs:{"images":item.feedback_images}})],1)]),_c('td',[_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.content)+" ")])]),_c('td',[_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","fab":"","small":"","color":"grey lighten-3"},on:{"click":function($event){return _vm.handleEditFeedbackImages(item)}}},[_c('v-icon',{attrs:{"color":"black","size":"24px"}},[_vm._v("mdi-square-edit-outline")])],1)],1)])}),_c('modal-edit-feedback',{attrs:{"product":_vm.productActive}})],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v("Không có dữ liệu")])])])]}}])},'v-data-table',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }