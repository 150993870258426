<template>
  <tp-modal
    class="modal-edit-feedback"
    max-width="700px"
    name="modal-edit-feedback"
    width="70%"
  >
    <v-card v-if="cloneProduct">
      <v-card-title class="d-flex justify-space-between align-center border-b">
        <div
          v-if="
            cloneProduct.feedback_images && cloneProduct.feedback_images.length
          "
          class="font-weight-bold"
        >
          {{
            `Feedback ${cloneProduct.option.product_name} (${cloneProduct.feedback_images.length} ảnh)`
          }}
        </div>
        <div v-else class="font-weight-bold">
          {{ `Feedback ${cloneProduct.option.product_name}` }}
        </div>

        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="pa-4" fluid>
        <v-card flat>
          <div class="font-weight-bold mb-3">Ảnh feebback</div>
          <draggable
            v-model="cloneProduct.feedback_images"
            v-horizontal-scroll
            class="d-flex flex-wrap gap-4"
            v-bind="dragOptions"
          >
            <v-hover
              v-for="(item, index) in cloneProduct.feedback_images"
              :key="index"
              v-slot:default="{ hover }"
              class="cursor-move"
            >
              <div class="gallery-item">
                <v-img
                  :aspect-ratio="1"
                  :src="item && item.slice(0, 5) !== 'blob:' ? item : undefined"
                  class="grey lighten-3 rounded tp-boder-thin"
                  height="120px"
                  width="120px"
                >
                  <v-fade-transition>
                    <v-row
                      v-if="hover"
                      align="center"
                      class="fill-height grey darken-4 ma-0"
                      justify="center"
                      style="opacity: 0.65"
                    >
                      <v-btn color="white" icon @click="previewImage(item)">
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>

                      <v-btn color="white" icon @click="removeImage(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-fade-transition>
                  <template v-slot:placeholder>
                    <v-row
                      align="center"
                      class="fill-height ma-0"
                      justify="center"
                    >
                      <v-progress-circular
                        color="grey lighten-5"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </v-hover>

            <tp-input-multi-image
              v-model="cloneProduct.feedback_images"
              :loading="loading"
              @change="onChangeImages"
              class="no-drag"
            >
            </tp-input-multi-image>
          </draggable>
        </v-card>

        <v-card class="mt-5" flat>
          <div class="font-weight-bold mb-3">Nội dung</div>
          <v-textarea
            v-model="cloneProduct.content"
            outlined
            rows="3"
            placeholder="Nhập feedback"
            hide-details
          ></v-textarea>
        </v-card>
      </v-container>

      <v-card-actions class="d-flex align-center justify-end border-t">
        <v-btn text color="grey darken-1" @click="closeModal">
          Hủy
        </v-btn>
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          :disabled="loading"
          :loading="loadingUpdate"
          @click="handleUpdate"
          >Lưu
        </v-btn>
      </v-card-actions>
    </v-card>

    <modal-preview-image :url="selectedImage"></modal-preview-image>
  </tp-modal>
</template>

<script>
import draggable from "vuedraggable";
import { cloneDeep } from "@vt7/utils";

import MediaService from "@/core/service/media.service";
import OrderService from "@/modules/Transaction/service/order.service";
import ModalPreviewImage from "@/core/components/modals/ModalProduct/components/ModalPreviewImage.vue";

export default {
  components: {
    draggable,
    ModalPreviewImage
  },

  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selectedImage: "",
      loading: false,
      loadingUpdate: false,
      cloneProduct: {}
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        filter: ".no-drag"
      };
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-edit-feedback"
      });
    },

    previewImage(url) {
      this.selectedImage = url;
      this.$modal.show({ name: "modal-preview-image" });
    },

    removeImage(link) {
      this.cloneProduct.feedback_images = this.cloneProduct.feedback_images.filter(
        item => {
          return item !== link;
        }
      );
    },

    async onChangeImages(files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }

      try {
        this.loading = true;
        const response = await MediaService.uploadMultipleImages(formData);

        this.cloneProduct.feedback_images =
          this.cloneProduct.feedback_images &&
          this.cloneProduct.feedback_images.length
            ? [...this.cloneProduct.feedback_images, ...response.data]
            : response.data;
      } catch (error) {
        console.log("onChangeImages", error);
      } finally {
        this.loading = false;
      }
    },

    async handleUpdate() {
      try {
        this.loadingUpdate = true;

        this.saleTabActive.details.forEach(item => {
          if (item.option.SKU === this.cloneProduct.option.SKU) {
            item.feedback_images = this.cloneProduct.feedback_images;
            item.content = this.cloneProduct.content;
          }
        });

        await OrderService.updateFeedbackImages(this.saleTabActive);

        // await this.$store.dispatch("SALE/setSaleTabActive", response);
      } catch (error) {
        console.log("handleUpdate", error);
      }

      this.loadingUpdate = false;
      this.closeModal();
    }
  },

  watch: {
    product: {
      handler(value) {
        this.cloneProduct = cloneDeep(value);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.border-b {
  border-bottom: 1px solid #e0e0e0;
}

.border-t {
  border-top: 1px solid #e0e0e0;
}

.cursor-move {
  cursor: move;
}

.ghost {
  opacity: 0.5;
}

.gallery {
  display: flex;
  width: 100%;

  &-add {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 120px !important;
    transition: all 0.4s ease;
    width: 120px !important;

    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }

  &-item {
    display: flex;

    .v-image {
      border: 1px solid #e5e5e5;
    }
  }
}

.gap-4 {
  gap: 16px;
}
</style>
